import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import Reviews from "../home/Reviews";
import EcommerceWrap from "./EcommerceWrap";
import {Helmet} from "react-helmet";
function EcommerceSolutions() {
  return (
    <>
     <Helmet>
        <title>E-Commerce Website Development Company | E-Commerce Solutions</title>
        <meta name="description" content="Choosing an eCommerce Development Company like DigiHunk will lead you to offer the top eCommerce Development Services with affordable eCommerce Website Development Services."/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <div className="creasoft-wrap">
        <Breadcrumb pageName="E-Commerce Solutions" pageTitle="E-Commerce Solutions" />
        <EcommerceWrap />
        <Reviews/>
        <SubscribArea />
      </div>
    </>
  );
}

export default EcommerceSolutions;
