import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Reviews extends Component {
  render() {
    return <>
    <section className="history-area sec-mar">
        <div className="container">
         
          <div className="row g-4">
              { /* <div className='col-lg-2'>
                   <a href='https://clutch.co/profile/digihunk-technologies-private' target="_blank"><img src={process.env.PUBLIC_URL + '/img/top-ecommerce-company.png'} alt="about-images" width={'100%'}/></a>
                </div>
                 <div className='col-lg-2'>
                   <a href='https://www.trustpilot.com/review/www.digihunk.com' target="_blank"><img src={process.env.PUBLIC_URL + '/img/digihunk-trustpilot.png'} alt="about-images" /></a>
                </div>
                 <div className='col-lg-2'>
                   <a href='https://www.designrush.com/agency/it-services/trends/benefits-of-cloud-computing' target="_blank"><img src={process.env.PUBLIC_URL + '/img/Best-Website Design-Company.png'}  alt="about-images" width={'100%'}/></a>
                </div>
                <div className='col-lg-2'>
                   <a href='https://www.goodfirms.co/company/digihunk-technologies-private-limited' target="_blank"><img src={process.env.PUBLIC_URL + '/img/top-app-development-company.png'} alt="about-images" /></a>
                </div>
 */}
                <div className='col-lg-2'>
                    <a href="https://www.designrush.com/press/top-web-design-companies-march-2023-press-release" target="_blank"> <img src={process.env.PUBLIC_URL + '/img/designrush.png'} alt="app developers india" title="App Developers India"  /></a>
                </div>
                { /*  <div className='col-lg-2'><br/><br/>
                    <a href="https://www.designrush.com/agency/profile/digihunk-technologies" target="_blank"> <img src={process.env.PUBLIC_URL + '/img/AccreditedAgencyBlue.png'} alt="app developers india" title="Accredited Web Development Company India" width={'100%'} /></a>
                </div> */}

          </div>
          
          
          </div></section>
    </>
  }
}
