import React from 'react'
import Breadcrumb from '../../common/Breadcrumb'
import AnimateLine from '../../common/AnimateLine'
import ErrorWrapper from './ErrorWrapper'
import SubscribArea from '../../common/SubscribArea'
function ErrorPage() {
  return (
    <>
    <div className="creasoft-wrap">
        <AnimateLine/>
     <Breadcrumb pageName="Error" pageTitle="Error"/>   
     <ErrorWrapper/>
     <SubscribArea/>
    </div>
    </>
  )
}

export default ErrorPage