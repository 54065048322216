import React from "react";



function TermsWrap() {
  return (
    <>
     <section className="history-area sec-mar">
      <div className="creasoft-wrap">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Terms</span>
              <h2>Terms & Conditions</h2>
            </div>
          </div>
           <div className="col-lg-12">
             <div className="single-details">
           
							
							<h3>1. Acceptance of Terms</h3>
<p>Your access to and use of DigiHunk Technologies Pvt. Ltd. website (“the Website”) is subject exclusively to these terms and conditions. You will not use the website for any purpose that is unlawful or prohibited by these terms and conditions. If you do not accept these terms and conditions you must immediately stop using the website else it will be termed as your acceptance.</p><br></br>

<h3>2. General</h3>
<p>DigiHunk Technologies Pvt. Ltd. will carry out work only where a written purchase order is provided by mail.</p><br></br>

<h3>3. Changes to Website</h3>

<p>DigiHunk Technologies Pvt. Ltd. reserves the right to:</p><br></br>

<p>3.1 Change or remove (temporarily or permanently) the website or any part of it without notice. DigiHunk Technologies Pvt. Ltd. shall not be liable to you for any such change or removal.</p><br></br>

<p>3.2 Change these terms and conditions at any time, and your continued use of the website following any changes shall be deemed to be your acceptance of such change.</p><br></br>

<h3>4. Website Design</h3>
<p>Though every effort will be made to ensure that the website and any work done by us is free of errors but DigiHunk Technologies Pvt. Ltd. cannot accept responsibility for any losses incurred due to malfunction, the website or any part of it. The web server, website, graphics and any programming code remain the property of DigiHunk Technologies Pvt. Ltd. until all outstanding accounts are paid in full. Any work done (unless specifically agreed) by DigiHunk Technologies Pvt. Ltd. remain the copyright of DigiHunk Technologies Pvt. Ltd. and may only be commercially reproduced or resold with the permission of DigiHunk Technologies Private Limited..</p><br></br>

<p>DigiHunk Technologies Pvt. Ltd. cannot take responsibility for any copyright infringements caused by materials submitted by the client.</p><br></br>

<p>Any additions to the brief will be carried out at the discretion of DigiHunk Technologies Pvt. Ltd. and where no charge is made by DigiHunk Technologies Pvt. Ltd. for such additions, DigiHunk Technologies Pvt. Ltd.  accept no responsibility to ensure such additions are error free and reserve the right to charge an according amount for any correction to these or further additions.</p><br></br>

<p>DigiHunk Technologies Pvt. Ltd. will not be liable for any costs incurred, compensation or loss of earnings due to the work carried out on behalf of the client or any of the clients appointed agents.</p><br></br>

<p>DigiHunk Technologies Pvt. Ltd. will not be liable for any costs incurred, compensation or loss of earnings due to the unavailability of the site, its servers, software or any material provided by its agents.</p><br></br>

<h3>5. Database, Application and E-Commerce Development</h3>
<p>DigiHunk Technologies Pvt. Ltd. cannot take responsibility for any losses incurred by the use of any software created for the client. Whilst every care has been taken to ensure products are problem free and accurate, the ultimate responsibility lies with the client in ensuring that all software is functioning correctly before use.</p><br></br>

<p>Where applications or sites are developed on servers not provided by DigiHunk Technologies Pvt. Ltd., the client is expected to provide or seek any information, additional software, support or co-operation pertaining to the server required in order for the application to be correctly developed. Where large applications are to be developed, it is the client’s responsibility to provide a suitable testing environment which is identical to the final production environment.</p><br></br>

<p>The client is expected to test fully any application or programming relating to a site developed by DigiHunk Technologies Pvt. Ltd. before being made generally available for use. Where “bugs”, errors or other issues are found after the site is live, DigiHunk Technologies Pvt. Ltd. will endeavor (but is not obliged to) to correct these issues to meet the standards of function outlined in the brief.</p><br></br>

<h3>6. Website Hosting</h3>
<p>Whilst DigiHunk Technologies Pvt. Ltd. offers hosting of websites, no guarantees can be made as to the availability or interruption of this service by DigiHunk Technologies Pvt. Ltd. cannot accept liability for losses caused by the unavailability, malfunction or interruption of this service.</p><br></br>

<p>DigiHunk Technologies Pvt. Ltd.  reserves the right to refuse to handle in any way, material which may be deemed offensive, illegal or in any way controversial. Fees relating to web hosting or domain names must be paid prior to the expiration date of the said service. If the fees remain unpaid at the time of expiration, DigiHunk Technologies Pvt. Ltd. can cancel the said service and not liable for any data lost as a result of the cancellation.</p><br></br>

<h3>7. Copyright</h3>
<p>7.1 All copyright, trade marks and all other intellectual property rights in the website and its content (including without limitation the website design, text, graphics and all software and source codes connected with the Website) are owned by or licensed to DigiHunk Technologies Pvt. Ltd. or otherwise used by DigiHunk Technologies Pvt. Ltd. as permitted by law.</p><br></br>

<p>7.2 In accessing the website you agree that you will access the content solely for your personal, non-commercial use. None of the content may be downloaded, copied, reproduced, transmitted, stored, sold or distributed without the prior written consent of the copyright holder. This excludes the downloading, copying and/or printing of pages of the website for personal, non-commercial home use only.</p><br></br>

<h3>8. Disclaimers and Limitation of Liability</h3>
<p>8.1 The Website is provided on an “AS IS” and “AS AVAILABLE” basis. To the extent permitted by law, DigiHunk Technologies Pvt. Ltd. will not be liable for any indirect or consequential loss or damage whatever (including without limitation loss of business, opportunity, data, profits) arising out of or in connection with the use of the website.</p><br></br>

<p>8.2 DigiHunk Technologies Pvt. Ltd. makes no warranty that the functionality of the website will be uninterrupted or error free, that defects will be corrected or that the website or the server that makes it available are free of viruses or anything else which may be harmful or destructive.</p><br></br>

<h3>9. Severance</h3>
<p>If any of these terms and conditions should be determined to be invalid, illegal or unenforceable for any reason by any court of competent jurisdiction then such term or condition shall be severed and the remaining terms and conditions shall survive and remain in full force and effect and continue to be binding and enforceable.</p><br></br>

<h3>10. Payment of Accounts</h3>
<p>A deposit may be required from a new client before any work is carried out. In all cases, website hosting fees and any costs incurred by DigiHunk Technologies Pvt. Ltd. on behalf of the client are payable in advance and are non-refundable.</p><br></br>

<p>It is the DigiHunk Technologies Pvt. Ltd. policy that any outstanding accounts for work carried out by DigiHunk Technologies Pvt. Ltd. or its affiliates are required to be paid in full, no later than 7 days from the date of the invoice unless by prior arrangement with DigiHunk Technologies Pvt. Ltd. If accounts are not settled or DigiHunk Technologies Pvt. Ltd. has not been contacted regarding the delay, access to the related website or service may be denied. Fees relating to web hosting or domain names must be paid prior to the expiration date of the said service. If the fees remain unpaid at the time of expiration, we will with immediate effect, cancel said service and any data held by said service will be removed.</p><br></br>

<h3>11. Governing Law These terms and conditions shall be governed by and construed in accordance with the law of India and you hereby submit to the exclusive jurisdiction of the Indian courts.</h3>

<p>Conditions: Ensure that the product is in warranty. Cancellation and refund requests for products shall not be eligible under manufacturer warranty. The refund shall be processed only after the product is shipped back and received by us. Need to attach a note along with the product returned for refund. The note should clearly state your name and address, contact number, and order number. Describe the problem in your own words. Without your order details processing refund / replacement would not be possible. If the return courier awb/tracking number is not intimated to DigiHunk Technologies Pvt. Ltd. shop customer support team is not eligible for refund. The order returned must accompany all the accessories which were serviced along with the order else the cost of the accessories shall be recovered from the refund amount.</p><br></br>

<p>Any charges levied as tax is not eligible for refund.</p><br></br>

<p>Process: Please connect with our customer care via Online Form or call. The customer care team will validate the request by checking the timelines, product type, warranty terms, etc and shall take the request for refund or exchange. Ensure to collect the “Return address” from digihunk.com customer care. Pack the product properly and label the product with the order number, return address and your address. Follow the instructions of customer care carefully for coordinating with the Courier Company. Ensure that the awb/ tracking number is shared with digihunk.com customer care, once the courier agency has picked up the product. The order shall be cancelled once we receive the product in undamaged condition [There should be no scratches, no dents etc.] Please ensure to pack the product properly before handing it over to courier representative.</p><br></br>

<p>Conditions: The product ordered has not been shipped within the estimated time mentioned at the time of placing the order. No intimation regarding delayed shipment of product is received either by call or sms. For non delivery, the issue needs to be reported within 15 working days to digihunk.com . Once the non delivery of the order is acknowledged, the request of cancellation and refund shall be initiated by DigiHunk Technologies Pvt. Ltd. customer care.</p><br></br>

<p>Please Note: On cancellation of an order, you are entitled to receive refund based on pay mode table mentioned in refund timelines plus the shipping charges. digihunk.com is not liable to pay any penalty or compensation to the customer for cancellation of any order, for reason mentioned above.</p><br></br>
							
		
							
	</div>
            </div>
       </div>
        
       
        
     
        
       
      </div>
      </section>
    </>
  );
}

export default TermsWrap;
