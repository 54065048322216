import React from 'react' 
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
} from "swiper";
SwiperCore.use([Navigation, Autoplay]);
function PartnarArea() {
    const partnarSlider={
        loop: true,
        slidesPerView: 5,
        spaceBetween: 20,
        speed: 1000,
        autoplay:{
          delay: 2500,
          disableOnInteraction: false,
        },
        navigation: {
            nextEl: '.swiper-button-next-c',
            prevEl: '.swiper-button-prev-c',
        },
        breakpoints: {
            320: {
                slidesPerView: 2,
            },
            576: {
                slidesPerView: 3,
            },
            992: {
                slidesPerView: 4,
            },
            1200: {
                slidesPerView: 5,
            }
        }
    }
  return (
    <>
      <section className="our-partner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="sec-title black layout2">
                <span>Trusted by more than</span>
                <h2>325+ Clients</h2>
                
                <div className="-partnerslider-navigator">
                  <div className="swiper-button-prev-c"><i className="bi bi-chevron-left" /></div>
                  <div className="swiper-button-next-c"><i className="bi bi-chevron-right" /></div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <Swiper {...partnarSlider} className="swiper partner-slider">
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-1.webp"} alt="Einfolge Technologies" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-2.webp"} alt="Gloss Skin & Hair Clinic" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-3.webp"} alt="Cricket Kenya" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-4.webp"} alt="Kenya Kabaddi Union" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-19.webp"} alt="East Africa Cup" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-5.webp"} alt="Dreamcatchers Films" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-6.webp"} alt="Lex Familia India" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-7.webp"} alt="BizzSetu" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-8.webp"} alt="Absolute Broadcast" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-9.webp"} alt="Policy Innovation Lab" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-10.webp"} alt="SkillsAndYou Consultants Pvt Ltd" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-11.webp"} alt="acpl.club" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-12.webp"} alt="The Green Kitchen" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-13.webp"} alt="Flying Fish" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-14.webp"} alt="Sports Box" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-15.webp"} alt="Inermanee Private Limited" />
                    </div>
                  </SwiperSlide>
                   <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-16.webp"} alt="Kabil Profession Services" />
                    </div>
                  </SwiperSlide>
                   <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-17.webp"} alt="Black Short India Limited" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-18.webp"} alt="Dhuha Naji Dubai" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-21.webp"} alt="Sports Guru Foundation" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-20.webp"} alt="HTC Sports Pvt Ltd" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-22.webp"} alt="Deesksha Sports Management" />
                    </div>
                  </SwiperSlide>
                   <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-23.webp"} alt="RUBOLO E-BUSINESS LLP " />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-24.webp"} alt="Kabil NGO" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <div className="single-partner">
                      <img src={process.env.PUBLIC_URL + "/img/partner/partner-25.webp"} alt="Scaff India" />
                    </div>
                  </SwiperSlide>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default PartnarArea