import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";
function MobileAppWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots of Creative</span>
              <h2>Mobile App Development</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                <p>DigiHunk Technologies is one of world best creative mobile apps Development Company Headquarters in New Delhi,India. Mobile application Development is the process by which application software is developed for low-power portable devices, such as personal iPhones or mobile phones. These applications can be installed on mobiles by downloading from the web. Nexevo Technologies is the Best Mobile Application Development Company in Delhi and we have designed and Developed many custom Mobile Applications for various purposes into different industries.</p>
                  <h2>Our mobile application development services and capabilities:</h2>
                  <div className="row">
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Android App Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="iOS App Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="Flutter Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="React Native Development" details=""/>
                    </div>
                   
                   
                    </div>
              </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileAppWrap;
