import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";
function UiUxWrap() {
  return (
    <>
       <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots Of Creative</span>
              <h2>UI/UX Design Service</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                    <p>We Design Visually Stunning & User- Friendly Customer Experience</p>
                    <p>Being in UI/UX for the past 10 years, we understand that design is created for and is meant to cater to the user, we are here to make what’s best for your them. We take our time to understand the user, their personal, their needs and requirements. Whatever product we create, has been well-thought out and has been designed for easy adoption by your user. Our goal is to make the product so simple that it is intuitive, clean and easy to get around.</p>
                    <p>Our UI Development team uses a creative technique to produce products that are both reliable and easy to use, starting with a competitive study and ending with an interactive prototype. We guarantee that the services we provide will lay the groundwork for a company's growth and branding.</p>
                    
                    <h2>Our UI / UX design services and capabilities:</h2>
                    <div className="row">
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Wireframing" details="A wireframe is a simple black and white layout that outlines the size and placement of elements. It is the first step in a design process therefore it has no color, fonts or branding. It’s a plan of action that’s going to lead us to the final deliverable."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="Cross-Platform Experiences Design" details="When designing a new product or feature, it’s important to think about the different types of platforms that the design will be experienced on. A platform is the medium that users experience your product on."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="Mobile App UX and UI Design Services" details="With vast array of apps introducing in the market, prominent user-interface is significant to entice customers. A best mobile UI designing company in India understands it well and provides solutions accordingly. Mobile applications are being introduced with smarter features and designs to help marketing campaigns, online business transactions and connectivity choices for always busy people around the world."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="Web Design Services" details="Website design is a fundamental part of the business, and a strategic tool you can use to attract, educate and inform consumers of your offerings. Any traffic on your website can convert into a lead and into a customer – that’s why, we believe, it is the first and last impression a surfer has. Our goal through the design process is to develop a user experience that delivers value at every step. We at Versatile take constant efforts to educate ourselves with the latest in the market."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title="Software Redesign" details="We help entrepreneurs who are planning to re build UI and UX designs for their mobile apps or websites or softwares. We have the expertise needed in all designing tools and technologies which enables us to make professional designs that catch the users’ attention and attract more customers to your product(s). Users always look for an attractive product with a good interface and a smooth experience that will enable them to be interactive and engaged and that is exactly what our technical team will help you achieve."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={6} image="/img/icons/service-icon-1.png" title="UI & UX Consulting" details="DigiHunk UI and UX experts smoothly integrate into the software development process to work on convenient and intuitive digital products. We have vast experience designing both complex B2B solutions and consumer apps.
As a UX and UI design services company, we follow modern trends and work hand-in-hand with the development team to create the most effective UX/UI solutions for web, iOS, and Android."/>
                    </div>
                   
                    </div>

                    <div className="row">
                       <h3>Our key UX factors</h3>
                        <p>We want to design experiences and change markets with our service. We develop things for customers from conception to release that look, feel, and function well. We plan each step of our work in advance:</p>
                      <div className="col-md-6 single-service wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                       <h4>Approach The Approach</h4>
                        <p>Every industry has a unique element that attracts potential customers. In order to create an effective strategy, we combine efforts with stakeholders, discuss needs with competitors and potential clients, and assess requirements.</p>
                       </div>
                       
                     
                       <div className="col-md-6 single-service wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <h4>Validation & Consultation</h4>
                        <p>In order to maintain transparency and offer input that enables our professionals and stakeholders to be on the same page, we let you validate the plan for comments. As a result, we update the product as needed and continue with the processing.</p>
                    </div>
                    <div className="col-md-6 single-service wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <h4>Design Success</h4>
                        <p>We define the approach to drive the design process after doing an analysis of your industry and business to determine the fundamental components of UI/UX designs.</p>
                    </div>
                    <div className="col-md-6 single-service wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <h4>Test To Experience</h4>
                        <p>We conduct tests at every stage of user flow mapping, wireframing, user interface design, interaction design, and prototyping to assure the smooth and user-friendly UI UX products.</p>
                    </div> </div>

                    <div className="row">
                      <h3>Designing Tools We Use</h3>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools3.png" alt="Photoshop"/>
                        <h4>Photoshop</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools4.png" alt="Illustrator"/>
                        <h4>Illustrator</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools5.png" alt="Figma"/>
                        <h4>Figma</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools2.png" alt="Adobe XD"/>
                        <h4>Adobe XD</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools9.png" alt="Indesign"/>
                        <h4>Indesign</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools1.png" alt="Sketch"/>
                        <h4>Sketch</h4>
                      </div>
                    </div>
              </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default UiUxWrap;
