import React from "react";
import { Link } from "react-router-dom";

function Footer(props) {
  const scrollTop=()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
     
      <footer className={props.footerAddclass} >
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link scrollTo={scrollTop} to={"/"}>
                      <img src={process.env.PUBLIC_URL +"/img/logo.png"} alt="DigiHunk Logo" />
                    </Link>
                  </div>
                  <p>
                    DigiHunk is the best Website Design & Development Company in New Delhi, India has a global reach with clients in UAE, Africa, USA & UK.

                  </p>
                  <p>CIN: U74990DL2018PTC342876</p>
                   <p>GSTIN: 07AAGCD9886J1ZW</p>
                  <ul className="social-media-icons">
                    <li>
                      <a href="http://facebook.com/digihunktech">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/digihunktech">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                     <li>
                      <a href="https://www.instagram.com/digihunk">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/digihunktech/">
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                   
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Our Services</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/ui-ux-design`} onClick={scrollTop}>UI/UX Design</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/web-development`} onClick={scrollTop}>Web Development</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/ecommerce-solutions`} onClick={scrollTop}>E-Commerce Solutions</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/mobile-app-development`} onClick={scrollTop}>Mobile App Development</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/digital-marketing`} onClick={scrollTop}>Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/graphic-design`} onClick={scrollTop}>Graphic Design</Link>
                    </li>
                    
                    
                    
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/about`} onClick={scrollTop}>About Us</Link>
                    </li>
                    
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/portfolio`} onClick={scrollTop}>Portfolio</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/blog`} onClick={scrollTop}>Blog</Link>
                    </li>
                    <li>
                      <Link to={`${process.env.PUBLIC_URL}/faq`} onClick={scrollTop}>FAQs</Link>
                    </li>
                     <li>
                      <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>Contact</Link>
                    </li>
                    
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt" />
                    </div>
                    <div className="phone">
                      <a href="tel:09990438600">+91-99904 38600 (India)</a>
                      <a href="tel:+971542170989">+971-542 17 0989 (UAE)</a>
                      
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="tell:care@digihunk.com">info@digihunk.com</a>
                     
                    </div>
                  </div>
                  <div className="address">
                    <a href="https://pages.razorpay.com/digihunk"><img src={process.env.PUBLIC_URL +"/img/razorpay.png"} alt="DigiHunk Online Payment Razorpay" width={'150'} /></a>
                   <a href="https://www.paypal.com/paypalme/digihunk"> <img src={process.env.PUBLIC_URL +"/img/paypal-logo-small.png"} alt="DigiHunk Online Payment Paypal"  width={'150'}/></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
          <div className="footer-bottom">
            <div className="row align-items-center">
              
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    &copy; 2023 <b>DIGIHUNK TECHNOLOGIES PVT. LTD., ALL RIGHTS RESERVED.</b> 
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                   <li>
                    <Link to={`${process.env.PUBLIC_URL}/terms-and-conditions`} onClick={scrollTop}>Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/privacy-policy`} onClick={scrollTop}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/refund-cancellation-policy`} onClick={scrollTop}>Refund & Cancellation Policy</Link>
                  </li>
                   <li>
                    <Link to={`${process.env.PUBLIC_URL}/disclaimer`} onClick={scrollTop}>Disclaimer</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
