import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import Reviews from "../home/Reviews";
import IvrWrap from "./IvrWrap";
import {Helmet} from "react-helmet";
function IvrSolutions() {
  return (
    <>
    <Helmet>
        <title>Best IVR Solution Provider in Delhi/NCR</title>
        <meta name="description" content=""/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <div className="creasoft-wrap">
        <Breadcrumb pageName="IVR Solutions" pageTitle="IVR Solutions" />
        <IvrWrap />
        <Reviews/>
        <SubscribArea />
      </div>
    </>
  );
}

export default IvrSolutions;
