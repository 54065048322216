import React from 'react'

function WhyChooseUs() {
  return (
    <>
      <section className="why-choose sec-mar wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img src={process.env.PUBLIC_URL + "/img/about-1.svg"} alt="why-choose images" />
                </div>
                
                <div className="years">
                  <h5>9+</h5>
                  <span>Years</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Why Choose</span>
                  <h2>What Makes Us One of The Top Rated Web Development Company In Delhi/NCR.</h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">1355</span><sup>+</sup>
                    <h5>Project Completed</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">300</span><sup>+</sup>
                    <h5>Satisfied Clients</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">15</span><sup>+</sup>
                    <h5>Expert Teams</h5>
                  </div>
                </div>
                <p>The look and feel of a company's website is crucial in creating a positive first impression. And we completely understand that as a web design business! So that you may be sure to keep one step ahead of the competition, we make sure to produce new designs while utilising top-notch web development techniques. You can get the necessary understanding of the calibre of our work—all of which is attributable to the superb skill set of our team—by reading the testimonials of our clients.</p>
               
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default WhyChooseUs