import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import Reviews from "../home/Reviews";
import WebHostingWrap from "./WebHostingWrap";
import {Helmet} from "react-helmet";
function WebHosting() {
  return (
    <>
    <Helmet>
        <title>Web Hosting Company India - Hosting Maintenance Agency</title>
        <meta name="description" content=""/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <div className="creasoft-wrap">
        <Breadcrumb pageName="Web Hosting" pageTitle="Web Hosting" />
        <WebHostingWrap />
        <Reviews/>
        <SubscribArea />
      </div>
    </>
  );
}

export default WebHosting;
