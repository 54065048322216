const data = [
    {
        id:1,
        title:"WordPress",
        image:"img/project/project-1.webp",
        category: "developing",
        details:"Policy Innovation Lab",
        url:"https://policyinnovationlab.in",
    },
    {
        id:2,
        title:"E-Commerce",
        image:"img/project/project-2.webp",
        category: "Ecom",
        details:"The Green Kitchen",
        url:"https://www.thegreenkitchen.co.in",
    },
    {
        id:3,
        title:"WordPress",
        image:"img/project/project-3.webp",
        category: "developing",
        details:"Kenya Kabbadi Union",
        url:"https://www.kabaddiunion.or.ke",
    },
    {
        id:4,
        title:"Web Design",
        image:"img/project/project-4.webp",
        category: "web",
        details:"Cricket Kenya",
        url:"https://www.kenyacricket.com",
    },
    {
        id:5,
        title:"Logo Design",
        image:"img/project/project-5.webp",
        category: "Graphic",
        details:"LADAKH UNITED CLUB",
        url:"#",
    },
    {
        id:6,
        title:"Web Design",
        image:"img/project/project-6.webp",
        category: "web",
        details:"Lex Famillia India",
        url:"https://www.lexfamiliaindia.com",
    },
    {
         id:7,
         title:"Web Design",
         image:"img/project/project-10.webp",
         category: "web",
         details:"East Africa Cup",
         url:"http://eastafricacup.com",
     },
    {
         id:8,
         title:"Web Design",
         image:"img/project/project-11.webp",
         category: "web",
         details:"ACPL.CLUB",
         url:"https://www.acpl.club",
     },
    {
         id:9,
         title:"Web Design",
         image:"img/project/project-12.webp",
         category: "web",
         details:"BizzSetu",
         url:"https://www.bizzsetu.com",
     },
    // {
    //     id:10,
    //     title:"UI Kit",
    //     image:"img/project/project-4.webp",
    //     category: "UI",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:11,
    //     title:"App",
    //     image:"img/project/project-3.webp",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
    // {
    //     id:12,
    //     title:"App",
    //     image:"img/project/project-1.webp",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
];

export default data;