import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from "./components/page/about/About";
import BlogDetails from "./components/page/BlogDetails/BlogDetails";
import BlogStandard from "./components/page/BlogStandard/BlogStandard";
import Cancellation from "./components/page/cancellation/Cancellation";
import Contact from "./components/page/contact/Contact";
import Disclaimer from "./components/page/disclaimer/Disclaimer";
import ErrorPage from "./components/page/error/ErrorPage";
import Faq from "./components/page/faq/Faq";
import HomePageTwo from "./components/page/homepage2/HomePageTwo";
import Pricing from "./components/page/pricing/Pricing";
import Privacy from "./components/page/privacy/Privacy";
import Project from "./components/page/project/Project";
import ProjectDetails from "./components/page/ProjectDetails/ProjectDetails";
import Service from "./components/page/service/Service";
import ServiceDetails from "./components/page/ServiceDetails/ServiceDetails";
import Team from "./components/page/team/Team";
import Terms from "./components/page/terms/Terms";
import "./index.css";
import {Helmet} from "react-helmet";
import UiUx from "./components/page/service/UiUx";
import WebDevelopment from "./components/page/service/WebDevelopment";
import EcommerceSolutions from "./components/page/service/EcommerceSolutions";
import MobileAppDevelopment from "./components/page/service/MobileAppDevelopment";
import WebApplicationDevelopment from "./components/page/service/WebApplicationDevelopment";
import WebsiteMaintenance from "./components/page/service/WebsiteMaintenance";
import DIgitalMarketing from "./components/page/service/Digital Marketing";
import GraphicDesign from "./components/page/service/GraphicDesign";
import WebHosting from "./components/page/service/WebHosting";
import IvrSolutions from "./components/page/service/IvrSolutions";
import BulkSmsService from "./components/page/service/BulkSmsService";
import BulkEmailService from "./components/page/service/BulkEmailService";
import Footer from "./components/common/Footer";
import Header from "./components/common/Header";
//import ReactGA from 'react-ga';

//Default Warniing Error Hide
console.log = console.warn = console.error = () => {};
 // const TRACKING_ID = "G-KN4V5VCXL1"; // YOUR_OWN_TRACKING_ID
 // ReactGA.initialize(TRACKING_ID);
 // ReactGA.send("pageview");
/*
=>version : 0.1
=>Event : Rendering al content to web
=>Action: define all routes and page
@return HTML
*/

function Root() {
  return <>

  <Helmet>
                <meta charSet="utf-8" />
                <title>DigiHunk: Best web development company in Delhi/NCR</title>
                <link rel="canonical" href="http://digihunk.com/" /></Helmet>
  <Router>
   <Header/>
    <Routes>
      {/*main layout*/}
       
      <Route  path="/" element={<HomePageTwo/>} />
     
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/about`}
          element={<About/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/contact`}
          element={<Contact/>}
        />
       
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/ui-ux-design`}
          element={<UiUx/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/web-development`}
          element={<WebDevelopment/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/ecommerce-solutions`}
          element={<EcommerceSolutions/>}
        />
         <Route
          exact
          path={`${process.env.PUBLIC_URL}/mobile-app-development`}
          element={<MobileAppDevelopment/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/web-application-development`}
          element={<WebApplicationDevelopment/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/website-maintenance`}
          element={<WebsiteMaintenance/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/digital-marketing`}
          element={<DIgitalMarketing/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/graphic-design`}
          element={<GraphicDesign/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/web-hosting`}
          element={<WebHosting/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/IVR-Solutions`}
          element={<IvrSolutions/>}
        />
        
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/blog`}
          element={<BlogStandard/>}
        />
       
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/blog-details`}
          element={<BlogDetails/>}
        />
        
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/project-details`}
          element={<ProjectDetails/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/portfolio`}
          element={<Project/>}
        />
       
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/faq`}
          element={<Faq/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/privacy-policy`}
          element={<Privacy/>}
        />
         <Route
          exact
          path={`${process.env.PUBLIC_URL}/terms-and-conditions`}
          element={<Terms/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/disclaimer`}
          element={<Disclaimer/>}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/refund-cancellation-policy`}
          element={<Cancellation/>}
        />
         <Route path="*" element={<ErrorPage/>}/>
        
     
    </Routes>
    <Footer/>
    
  </Router>
</>
}

export default Root;

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root")
);
