import React from 'react'
import { Link } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  
  Autoplay,
  Navigation,
  EffectFade,
  Pagination
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade,Pagination,]);
function Testimonial() {
    const testimonialSlider = {
        loop: true,
        slidesPerView: 2,
        centeredSlides: true,
        duration: 5000,
        speed: 1000,
        autoplay:{
          delay: 3500,
          isableOnInteraction: true,
        },
        spaceBetween: 30,
        slideToClickedSlide: true,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 2,
            },
            1400: {
                slidesPerView: 2,
            },
            1800: {
                slidesPerView: 2,
            }
        }
    }
  return (
    <>
      <section className="testimonial-area sec-mar-top">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title black">
              <span>Testimonial</span>
              <h2>Our clients say</h2>
             
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Rakkesh Chandra</h5>
                  <span>rakkeshchandra.com</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Great commitment towards excellence at work. Strongly recommended.</p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + '/img/testimonial_1.webp'} alt="testimonial-img" />
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Pratyay Jagannath</h5>
                  <span>Director, KPS PVT. LTD.</span>
                  <div className="stars">
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                    <Link to={"#"}><i className="fas fa-star" /></Link>
                  </div>
                  <p>Excellent service and quality. They makes sure that you achieve the quality is needed.</p>
                  <div className="reviewer">
                    <img src={process.env.PUBLIC_URL + "/img/testimonial_2.webp"} alt="testimonial-img" />
                  </div>
                </div>
              </SwiperSlide>
              
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>  
    </>
  )
}

export default Testimonial