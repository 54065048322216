import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import Reviews from "../home/Reviews";
import GraphicDesignWrap from "./GraphicDesignWrap";
import {Helmet} from "react-helmet";
function GraphicDesign() {
  return (
    <>
    <Helmet>
        <title>Graphic Design Company | Graphic Design Services – DigiHunk</title>
        <meta name="description" content="Get visualized by the viewers with DigiHunk, a Graphic Design Company that is passionate about their provided Graphic Design Services with next-level creativity."/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <div className="creasoft-wrap">
        <Breadcrumb pageName="Graphic Design" pageTitle="Graphic Design" />
        <GraphicDesignWrap />
        <Reviews/>
        <SubscribArea />
      </div>
    </>
  );
}

export default GraphicDesign;
