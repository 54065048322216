import React from "react";
import SubscribArea from "../../common/SubscribArea";
import AboutArea from "../home/AboutArea";
import FeaturesArea from "../home/FeaturesArea";
import PartnarArea from "../home/PartnarArea";
import Reviews from "../home/Reviews";

import ServiceArea from "../home/ServiceArea";
import Technology from "../home/Technology";
import Testimonial from "../home/Testimonial";
import ProjectWrap2 from "../project/ProjectWrap2";
import Hero2 from "./Hero2";
import {Helmet} from "react-helmet";
function HomePageTwo() {

  return (
    <>
      <Helmet>
        <title>Web Development Company India - Digital Marketing Agency</title>
        <meta name="description" content="One of the fastest growing web development agency in Delhi/NCR,India, having worked with over 300+ Clients on Web development, Digital Marketing, Website UI/UX etc."/>
        <meta name="keywords" content="Web Development Company in India, Web Design Company in Delhi, Digital Marketing Agency in India, Graphic Design Company in India"/>
      </Helmet>
      <div className="creasoft-wrap layout2">
        <Hero2 />
        <AboutArea layoutClass="layout2" white="white" image="/img/logo.svg"/>
        
        <FeaturesArea/>
        <ServiceArea layoutClass="layout2" white="white"/>
        <PartnarArea/>
         <SubscribArea />
       <Technology/>
       <Testimonial/>
        <ProjectWrap2 white="white"/>
      <Reviews/>
        
        
         

      </div>
    </>
  );
}

export default HomePageTwo;
