import React from 'react'
import BlogStandarLayoutTwo from './BlogStandarLayoutTwo'
import StandardBlogLayoutOne from './StandardBlogLayoutOne'
import Pagination from '../../common/Pagination'
import SearchWidget from '../blog/SearchWidget'
import CatagoryWidget from '../blog/CatagoryWidget'
import NewPostWidget from '../blog/NewPostWidget'
import BannerWidget from '../blog/BannerWidget'
import PostTagWidget from '../blog/PostTagWidget'

function BlogStandardWrap() {
  return (
    <>
        <div className="blog-standard sec-mar-top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 or-2">
                        <div className="blog-standard-details">
                        <StandardBlogLayoutOne/>
                    
                        </div>
                        
                    </div>
                    <div className="col-lg-4 or-1">
                        <SearchWidget/>
                        <CatagoryWidget/>
                        
                        <BannerWidget/>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default BlogStandardWrap