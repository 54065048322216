import React from "react";

function CancellationWrap() {
  return (
    <>
      <section className="history-area sec-mar">
      <div className="creasoft-wrap">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Cancellation Policy</span>
              <h2>Refund & Cancellation Policy</h2>
            </div>
          </div>
           <div className="col-lg-12">
             <div className="single-details">
           <p>• All prices are quoted  are inclusive of GST unless specified.</p><br></br>

<p>• All invoices are to be processed as per the schedule via your preferred method of payment.</p><br></br>

<p>• Receipt of payment is deemed as acceptance of the quote, milestone, delivery, project and terms & conditions.</p><br></br>

<p>• DigiHunk Technologies Pvt. Ltd. reserves the right to negotiate and refund appropriate portion of the amount paid by customer towards the requested service. If you cancel your order after making the payment, but the work has not commenced, we will refund 75% of the payment that you have already made. If the order is cancelled after the commencement of work, the maximum refund or charge will be 50% of the invoice. There will be no refund or adjustments if cancellation is called when more than 50% of the work has been completed. All cancellations must be received in writing and can be sent via regular mail, email or fax. Telephone requests on cancellations will not be accepted.</p><br></br>
<p>
For minor or once-off works, payments need to be made either in advance or as per the terms mentioned in the invoice/proposal. If you require any changes to the terms, you must inform us in writing (emails accepted) within two working days of the receipt of the same.</p><br></br>

<h3>Late fees and charges: </h3>
<p>All outstanding invoices will incur a late payment fee of 10% of the pending amount.</p><br></br>

<h3>Due date:</h3> 
<p>Is the date on which the payment is due as per the terms on the invoice/proposal</p><br></br>

<h3>Outstanding Invoice:</h3> 
<p>An invoice is deemed to be outstanding if the payment is still due after 14 days of the expiry of the due date.
</p><br></br>

<h3>Debt Collection:</h3>
 <p>Client is provided a further 7 days after late payment fee is applied to pay the invoice. Failure to pay will result in the outstanding amount referred to a debt collector. Synapse Worldwide will not be liable for any costs or charges associated with the recovery of the outstanding amount. Any charges associated with the recovery of the outstanding amount (debt collectors, administration charges, legal fees) will be referred to the client for processing.
</p><br></br>
<p>• If you are late with a payment or have any queries regarding the Invoice, please contact Accounts Manager immediately on receiving the invoice or reminder emails.</p><br></br>

<p>• All communications/correspondences are generally done via emails. It is client’s responsibility to keep us updated with their relevant email addresses.</p><br></br>

<p>• Any payment relating to the domain name registration renewal or hosting or any 3rd party products or service purchased on behalf of the client will have to be paid in full and is non-refundable. In case of all renewal cancellations, we must be notified atleast 30 days before the renewal date.</p><br></br>

<p>• Digihunk Technologies generally builds and tests the websites/applications on our own servers or hosted domains. The website/application will be transferred-off to the nominated 3rd party server upon full payment of all invoices and dues.</p><br></br>


		
							
	</div>
            </div>
       </div>
        
       
        
     
        
       
      </div>
      </section>
    </>
  );
}

export default CancellationWrap;
