import React from "react";




function PrivacyWrap() {
  return (
    <>
    <section className="history-area sec-mar">
      <div className="creasoft-wrap">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Our Policy</span>
              <h2>Privacy Policy</h2>
            </div>
          </div>
           <div className="col-lg-12">
             <div className="single-details">
              <p>This legal notice pertains to web site “digihunk.com” here after referred to as “DigiHunk Technologies Pvt. Ltd.”.</p><br></br>

<h3>Welcome</h3>
<p>This site is owned by DigiHunk Technologies Pvt. Ltd.,. DigiHunk Technologies Pvt. Ltd. would like to safeguard the information you provide to us about yourself. This Privacy Policy is designed to tell you about our practices regarding collection, use, and disclosure of information that you may provide via this site. Please be sure to read this entire Privacy Policy before using, or submitting information, to this site. Use of this site constitutes your consent to our Privacy Policy.</p><br></br>

<p>Intellectual copyright All information displayed, transmitted or carried on www.digihunk.in is protected by copyright and other intellectual property laws.</p><br></br>

<p>You may not, under any circumstances, modify, publish, distribute, repost, perform, display or in any way commercially exploit any of the content on this web site.</p><br></br>

<h3>Your Consent</h3>
<p>By using this site, you agree with the terms of this Privacy Policy. Whenever you submit information via this site, you consent to the collection, use, and disclosure of that information in accordance with this Privacy Policy.</p><br></br>

<h3>Active Information Collection</h3>
<p>Like many Web sites, this site actively collects information from its visitors by permitting you to communicate directly with us via e-mail and feedback forms. Some of the information that you submit may be personally identifiable information (that is, information that can be uniquely identified with you, such as your full name, address, e-mail address, phone number, and so on).</p><br></br>

<p>Some areas of this site may require you to submit information in order for you to benefit from the specified features (such as newsletter subscriptions, order processing, submitting a resume). You will be informed at each information collection point what information is required and what information is optional.</p><br></br>

<h3>Passive Information Collection</h3>
<p>As you navigate through a Web site, certain information can be passively collected (that is, gathered without your actively providing the information) using various technologies and means, such as navigational data collection.</p><br></br>

<p>This site may use Internet Protocol (IP) addresses. An IP Address is a number assigned to your computer by your Internet service provider so you can access the Internet and is generally considered to be non-personally identifiable information, because in most cases an IP address is dynamic (changing each time you connect to the Internet), rather than static (unique to a particular user’s computer). We may use your IP address to diagnose problems with our server, report aggregate information, determine the fastest route for your computer to use in connecting to our site, and administer and improve the site.</p><br></br>

<h3>Use and Disclosure of Information</h3>
<p>Except as otherwise stated, we may use your information to improve the content of our site, to customize the site to your preferences, to communicate information to you (if you have requested it), for our marketing and research purposes (could be on users demographics, interests and behavior), and for providing you with the latest information for direct marketing purposes where we think you may be interested in the products and services of some of our affiliates or third parties.</p><br></br>

<p>If you provide personally identifiable information to this site, we may combine such information with other actively collected information unless we specify otherwise at the point of collection. We will take reasonable measures to prevent personally identifiable information from being combined with passively collected information, unless you consent otherwise.</p><br></br>

<p>DigiHunk Technologies Pvt. Ltd. may disclose your personally identifiable information to other DigiHunk Technologies Pvt. Ltd. affiliates that agree to treat it in accordance with this Privacy Policy. In addition, we may disclose your personally identifiable information to third parties, located in India and/or any other country, but only</p><br></br>

<p>(i) to contractors we use to support our business (e.g., fulfillment services, technical support, delivery services, and financial institutions), in which case we will require such third parties to agree to treat it in accordance with this Privacy Policy;</p><br></br>

<p>(ii) in connection with the sale, assignment, or other transfer of the business of this site to which the information relates, in which case we will require any such buyer to agree to treat it in accordance with this Privacy Policy; or</p><br></br>

<p>(iii) Where required by applicable laws, court orders, or government regulations. In addition, we will make full use of all information acquired through this site that is not in personally identifiable form.</p><br></br>

<h3>Links from Other Web Sites</h3>
<p>Web sites that have links on our site may collect personally identifiable information about you. The data protection practices of those Web sites linked to DigiHunk Technologies Pvt. Ltd. are not covered by this policy statement. You are advised to check the data protection policies of these third party Web sites yourself before using those sites.</p><br></br>

<h3>Security</h3>
<p>We take reasonable steps to protect your personally identifiable information as you transmit your information from your computer to our site and to protect such information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no data transmissions over the internet can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, we cannot ensure or warranty the security of any information you transmit to us via the internet, and you do so at your own risk. Once we have received your transmission, we will use our best efforts to ensure its security on our systems.</p>
							
	</div>
            </div>
       </div>
        
       
        
     
        
       
      </div>
      </section>
    </>
  );
}

export default PrivacyWrap;
