import React from 'react'
import { Link } from 'react-router-dom'

function NewPostWidget() {
    const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
     <div className="sidebar-widget">
        <h4>Newest Post</h4>
        <div className="recent-post">
          <div className="recent-thumb">
            <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}><img src={process.env.PUBLIC_URL + "/img/blog/blog-1.jpg"}  alt="catagoryImg" /></Link>
          </div>
          <div className="recent-post-cnt">
            <span>21.05.22</span>
            <h5><Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>SEO vs. PPC: Which is Better in the Long Run?</Link></h5>
          </div>
        </div>
        
      </div>   
    </>
  )
}

export default NewPostWidget