import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AboutWrap from "./AboutWrap";
import {Helmet} from "react-helmet";
import Reviews from "../home/Reviews";
function About() {
  return (
    <>
   
        <Helmet>
        <title>DigiHunk: Web Design Company | SEO Company India</title>
        <meta name="description" content="When you are with DigiHunk, the Top Web Design and Development Company, get an aesthetic look with our Web Design agency in India to expand your business."/>
        <meta name="keywords" content="Website Development Compnay Near Me, Wesite Designer in India, Web Developer for Hire in India "/>
      </Helmet>
                
          
      <Breadcrumb pageName="About Us" pageTitle="About Us" />
      
      <AboutWrap />
      <Reviews/>
      <SubscribArea />
    </>
  );
}

export default About;
