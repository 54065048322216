import React from 'react'


function Technology(props) {
  return (
    <>
      <section className="about-area sec-mar-bottom sec-mar-top">
        <div className="container">
           
          <div className="row ">
            <div className="col-md-7" >
                <br></br><br></br><br></br><br/>
                <br></br>
                 <h1>Technologies We Use</h1>
                <p>Our expert business planners are aware of consumer demand, the current digital trend, and the technologies that are generating the most excitement among users. At DigiHunk, we offer all of the top technologies on the market today. Our knowledgeable staff can create a frontend or backend system to solve your complicated challenges using the most up-to-date technologies.</p>
            </div>
            <div className="col-lg-5 or-2 wow animate fadeIn " data-wow-delay="200ms" data-wow-duration="1500ms">
              <img src={process.env.PUBLIC_URL + '/img/technologies.png'} alt="web development technologies" />
            </div>
            
          </div>
        </div>
      </section>  
      
    </>
  )
}

export default Technology