import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";

function WebDevelopmentWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots of Creative</span>
              <h2>Web Development</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                 <p>DigiHunk Technologies is eminent Web Development Company in New Delhi, India. Our years of experience in programming using latest technologies as ReactJs, Angular, MongoDB, NodeJs, MySQL, CodeIgnitor, Laravel and many other technologies permit us to create a completely elegant and uniquely customized website with the construction of applications that have best influence on your necessity.</p> 
                 <h2>Our web development services and capabilities:</h2>
                  <div className="row">
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="PHP Development" details="A significant portion of the technology stack for dynamic website developmentis composed of the PHP language. It is among the most adaptable, reliable, and strong programming languages for creating extremely scalable online applications. PHP, being an open-source technology, has been continuously being improved and enhanced. Our team of web application developers in India at DigiHunk Technologies is continuously updating our skillset to adapt this continuously changing language. "/>
                    </div>
                   
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="React JS Development" details="ReachJS is the finest alternative if you're seeking for affordable web  solutions, and DigiHunk Technologies is a web development company in India you should take into consideration for specialised React.JS development. We have a group of knowledgeable and talented ReactJS engineers who have the ability to apply cutting-edge innovations and create business-driven applications."/>
                    </div>
                     <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="Node JS Development" details="DigiHunk Technologies, a pioneer in Node js web development, is your one-stop shop for creating feature-rich, performant, and scalable web and mobile apps. We are constantly equipped to construct Node js server-side applications and backend solutions thanks to our superior skill set and current knowledge of the language. We are the Node.js development business for you, whether you need to convert your current backend system to NodeJS or want a robust Node.js API."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="Angular Development" details="To meet your needs for creating interactive apps and online applications, DigiHunk Technologies, an Angular development firm, can help. The implementation of cutting-edge, unique features and designs in your product is guaranteed by our qualified specialists with extensive Angular web development expertise. We only provide you the highest calibre Angular development services since we are a top Angular development business. "/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title="CodeIgniter Development" details="CodeIgniter is a versatile PHP platform, the stable framework for PHP Codeigniter web developers that can create complete web applications by utilizing a simple and elegant toolkit. If you are a growing business that lives on a real-life basis with popular hosting accounts and customers with schedule constraints and are sick of broad and comprehensive systems, codeigniter web development services might be a good fit. "/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title="Laravel Development" details="As a leading Laravel framework development company, we have created multiple projects with waterfall and agile methodologies.

Either you can share with us the methodology, you want us to follow, or based on your requirement, we suggest one of them to help you get the best solution in a timely manner."/>
                    </div>
                     <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title="Django Development" details="Django, a high-level open-source web framework dependent on the object-oriented Python programming language, propels agile and clean solution development. It tends to be executed in both straightforward and complex sites. Its Framework is liked by most Python designers since it empowers them to construct applications quickly with the least coding. Inferable from its straightforward and clean coding features, the entire web development measure turns out to be profoundly successful and proficient. "/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title=".Net Development" details="As a proficient .NET development company, we build secure and scalable ASP .NET development solutions utilizing the potential and versatility of the .NET platform to build a variety of applications for diverse domains. Microsoft’s flagship .NET platform has been powering many dynamic web apps and it is a preferred development platform loved by developers and enterprises.
"/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={6} image="/img/icons/service-icon-1.png" title="WordPress" details="At DigiHunk Technologies we understand your business and the market in which you operate. We are aware of the industry trends and your competitor moves and how they impact your brand value and position. Keeping all these factors in mind, our experts dig deep to harness the full potential of WordPress and come up with several website options that meet your needs and your budget. In this initiative, we integrate the best WordPress theme and the best plugin to create a customized website for you."/>
                    </div>
                     <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={6} image="/img/icons/service-icon-1.png" title="Joomla" details="DigiHunk Technologies has been using Joomla now for more than a decade. We have built hundreds of websites for our clients with Joomla and they are very satisfied with our work. The reason is because Joomla is extremely powerful and can stretch to any number of pages when built into a website. It offers beautiful and stunning themes that can be used when building any website – either basic or complex; or with some customization to meet the exact needs of our clients. Joomla is also secure and mobile friendly."/>
                    </div>
                   
                    </div>
              </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default WebDevelopmentWrap;
