import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";
function DigitalMarketingWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots of Creative</span>
              <h2>Digital Marketing Services</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                <p>DigiHunk Technologies is a one of the award winning Internet Marketing Company in Delhi. We provide a variety of Internet marketing services both locally in Bangalore and globally. Our Internet marketing specialists have understanding of social media optimization, link building, search engine marketing, and digital internet advertising. All of our clients can get online marketing services from us at reasonable prices. On this page, we have a list of all the digital marketing services. You can look through them and decide whether consigning a project with us is the best course of action!</p>
                <img src="img/digital.svg" alt="top digital marketing company in india"  height="400"/>
              </div>
              <div className="row pt-5 pb-5">
                      <h2>Our digital marketing services and capabilities:</h2>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Search Engine Optimisation" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="Social Media Optimisation" details=""/></div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="Search Engine Marketing" details=""/></div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="Social Media Marketing" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title="SMS Marketing" details=""/>
                    </div>
                    
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={6} image="/img/icons/service-icon-1.png" title="Email Marketing" details=""/>
                    </div>
                     <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={7} image="/img/icons/service-icon-1.png" title="WhatsApp Marketing" details=""/>
                    </div>
                    
                   
                    </div>
                    <h3>Why do your business need digital marketing?</h3>
                <h5>Digital marketing company Delhi/NCR:</h5>
                <p>We have sufficient expertise in digital marketing services, and our digital marketing specialists in New Delhi have used every cutting-edge concept for our clients' viral business success! DigiHunk Technologies is focusing on giving clients a return on their investment. Choosing the best digital marketing company for your business may be very difficult, and the majority of people fall for the exaggerated claims and unrealistic guarantees provided by these providers. Digital Creatives at Nexevo Technologies create and implement digital marketing strategies and plans that properly suit our clients' needs and produce fruitful results.</p><br/>
                <h5>Digital Marketing Agencies India:</h5>
                <p>We prioritise helping start-ups, solopreneurs, and smaller businesses achieve their largest goals and reach new levels of profitability. Our desire to take on challenging projects, learn about, and develop strategies for new business and service verticals is fully satisfied by this approach. Every web project is handled by the necessary web professionals who share the same ethics and concentrate on the unique online marketing requirements of the customer. This also gives us the ability to manage both high-budget and low-budget websites with greater performance levels. Regardless of the size of your company, you will receive the same level of service responsibility.</p>
            </div>
            <div className="col-lg-4">
              <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default DigitalMarketingWrap;
