import React from "react";
import AnimateLine from "../../common/AnimateLine";
import AboutArea from "../home/AboutArea";
import PartnarArea from "../home/PartnarArea";
import WhyChooseUs from "./WhyChooseUs";
import Team from "../team/TeamWrap";
function AboutWrap() {
  return (
    <>
      <div className="creasoft-wrap">
        <AnimateLine />
       
        <AboutArea image="/img/logo-dark.svg"/>
       <PartnarArea/>
         <WhyChooseUs />
      
     
        
       
      </div>
    </>
  );
}

export default AboutWrap;
