import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen]=useState(false)
  return (
    <>
      <section className="about-area sec-mar-bottom sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.black}`}>
                <span>Know About Us</span>
                <h2>Our Story</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>We Build and Market your extreme ideas to global</h3>
                <p className='text-justify'>DigiHunk Technologies has effectively established a reputation for being a reputable web development firm. No matter what, our team of highly skilled professionals in UI/UX design, application development, web design, and web development will always bring their best. The utmost happiness of our customers is exactly what we strive for.</p>
                <div className={`${"company-since"} ${props.white}`}>
                 
                  <h4>Trusted by more than 300+ clients <span>Since 2013.</span></h4>
                </div>
              
              </div>
            </div>
            <div className="col-lg-5 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img src={process.env.PUBLIC_URL + '/img/banner.svg'} alt="about-images" />
                </div>
               
              </div>
            </div>
          </div>

          
        </div>
      </section>  
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay = {1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment> 
    </>
  )
}

export default AboutArea