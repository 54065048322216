import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap2() {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title black">
              <span>Recently work</span>
              <h2>Our Work</h2>
             
            </div>
          </div>
          <ProjectCardFilter/>
        </div>
      </div>
    </>
  );
}

export default ProjectWrap2;
