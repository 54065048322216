import React from "react";
import ContactForm from "./ContactForm";

function WebHostingWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots Of Creative</span>
              <h2>Web Hosting</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                <h3>Shared Hosting</h3>
							  <p>All Linux Shared Hosting Plans come with cPanel, to make hosting easy for everyone. Setting-up addon domains, Emails, FTP and Databases is convenient with our FREE cPanel.</p>
                <h3>VPS Hosting</h3>
							  <p>With VPS Hosting, you get complete isolation on a physical server for your hosting. Your Virtual Private Server will be unaffected by other users and with root access you can install whatever applications you require on your server. The CPU, RAM, HDD, and Bandwidth allocated to your VPS Hosting plan will always be available for your applications. A VPS server gives you all the features of a Dedicated Server at an affordable price.</p>
              </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default WebHostingWrap;
