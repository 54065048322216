import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";
function WebApplicationWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots of Creative</span>
              <h2>Web Application Development</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                <p>We are a web development company that purely focuses on building fully functional, robust, and scalable web applications. We build custom web apps using the right technology stacks that align with your business objectives and help you achieve maximum growth. Our end goal is to build quality web applications for you that maximize resource utilization at a low cost.</p>
                <p>Our web application development services include design and development, testing, and support and maintenance. Along with that, our experienced web app developers also help you in technology consultation, modernizing legacy architectures, and migrating to new web architecture.</p>
                 <h2>Our web application development services and capabilities:</h2>
                  <div className="row">
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Custom Web Application Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="Business Web Application Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="Enterprise Web Application Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="SaaS Web Application Development" details=""/>
                    </div>
                   
                   
                    </div>

                    
              </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default WebApplicationWrap;
