import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AboutWrap from "./DisclaimerWrap";

function Disclaimer() {
  return (
    <>
      <Breadcrumb pageName="Disclaimer" pageTitle="Disclaimer" />
      <AboutWrap />
      <SubscribArea />
    </>
  );
}

export default Disclaimer;
