import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";
function IvrWrap() {
   
   
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>IVR and Virtual Number Service Provider in Delhi/NCR</span>
              <h2>IVR Solutions</h2>
              
            </div>
          </div>
              <div className="service-details-content">
               
                <p>
                 A high-end, adaptable cloud-based IVR system is what DigiHunk Technologies offers, and it can be used by many different company areas. We are among the top IVR service providers in India and provide IVR services at reasonable costs.
                </p>
                <h3>Best IVR Service Provider in Delhi/NCR India</h3>
                <p>
                 Interactive Voice Response (IVR) is a service that functions with the help of an automated voice response system. The IVR System offered by DigiHunk Technologies works on cloud-based technology. It benefits both businesses and customers thanks to a variety of advantages. Callers can enter information into the pre-recorded voice system using their voice, DTMF tones, or the telephone keypad. It receives input from users, processes their request, and then responds either by sending calls to the appropriate department or providing an automated voice response. Businesses can get a virtual IVR number from DigiHunk Technologies together with an auto-attendant feature. Businesses can obtain additional functionality with multi-level menu selections based on their demands. It makes virtual phone system navigation easier; allowing callers to get the right solution or reach the right department.
                </p>
                <p>Using a cloud IVR system, DigiHunk Technologies assists clients in obtaining the information they need about the goods or services they intend to purchase or are already consuming. Businesses may now provide helpful information to clients when they need it thanks to an automated communication system. Businesses greatly profit from this recent development in computer and telephone technologies. For both inbound and outbound calls for a variety of applications, they are using the IVR (Interactive Voice Response) System features. for example, the telecom industry, banking industry, e-commerce sector, travel portals, railway inquiries, flight bookings, movie ticket booking, food delivery apps, cab booking, and many others. In addition to these uses, IVR systems aid organisations in marketing by assisting in the generation of leads.</p>
                <h3>Benfits of using IVR services in India</h3>
                <div className="row">
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="
Offers first contact resolutions" details="It is now possible to provide first contact resolutions to callers with the help of the IVR System. As it redirects the calls to the right department or to the concerned person only who can offer the right solution to the caller’s query. This saves time for both callers and agents; and adds customer satisfaction also."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="
Boosts customer service efficiencies" details="With the help of IVR Services, customer service has improved a lot. As most of the small queries are answered by the automated voice response system. So, customer service agents get ample time to resolve the other major or important queries of customers on time."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="
Increases agent’s efficiency" details="IVR System transfers customer’s calls to the right agent or department by understanding their queries. It saves agents from wasting their time on wrong queries and utilizing all their time in resolving the queries they are experts in. It increases the overall productivity of the customer service agent."/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="
Reduces operational cost" details="The Interactive Voice Response (IVR) System helps in cutting the extra cost that the businesses were investing in call attendants, receptionists, or customer service. By making use of IVR solutions, organizations require less workforce for handling customer service. Most of the manual calling work is now automated and all of this reduces the operational cost."/>
                    </div>
                   
                   
                    </div>

                
              </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default IvrWrap;
