import React from "react";
import emailjs from "@emailjs/browser";
import { useRef } from 'react';

function ContactForm() {
     const form =useRef();
  const sendEmail=(e)=>{
    e.preventDefault();
    
    emailjs.sendForm('service_5p2tgz3','template_w6m2qyo',form.current,'hv8RKgUgWsLCWkGaP')
      .then((result) => {
          console.log(result.text);
          alert('Thank You for showing interest in our services!! We will contact you soon.');
           e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <>
      <div className="sidebar-widget">
       <div className="getin-touch">
            <div className="row">
              <div className="col-md-12">
                <div className="contact-form wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                  <h3>START GROWING YOUR BUSINESS WITH US
</h3>
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="row">
                      <div className="col-12">
                        <input type="text" name="user_name" placeholder="Enter your name" required />
                      </div>
                      <div className="col-md-12">
                        <input type="text" name="user_phone" placeholder="Enter your phone number" required/>
                      </div>
                      <div className="col-md-12">
                        <input type="email" name="user_email" placeholder="Enter your email" required/>
                      </div>
                      
                      <div className="col-12">
                        <textarea name="message" cols={30} rows={10} placeholder="Your message" defaultValue={""} />
                        <input type="submit" defaultValue="Send Message" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              
            </div>
          </div>
     </div>
          <div className="sidebar-banner">
           
                <img src={process.env.PUBLIC_URL + '/img/widget-banner-bg.jpg'} alt="Banner images" />
                <div className="banner-inner">
                  <h3>
                    We are just a  <span>call away</span>
                    <img
                      className="angle"
                      src={process.env.PUBLIC_URL + "/img/arrow-angle.png"}
                      alt=""
                    />
                  </h3>
                  <a href="tel:+919990438600">+91-99904 38600</a>
                </div>
              </div>
              
             
    </>
  );
}

export default ContactForm;
