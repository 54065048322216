import React from "react";
import ContactForm from "./ContactForm";

function WebsiteMaintenanceWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots of Creative</span>
              <h2>Website Maintenance Services</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                <p>DigiHunk Technologies Website Maintenance Services are currently specialized in designed website and are up to date at keeping the website services which is why we provide our clients with fast acting, experienced and approachable website maintenance services. We are considerate about maintaining the website which is created by the user and update the website to surpass the users’ opponent in any way even such as simple change in text, adding additional complex functionality or tools to the websites.</p>
                <h3>Why choose us for Your Website Maintenance?</h3>
                <img src="img/maintenance.svg" alt="best website maintenance service provider in india" height="400"/>
                <p className="pt-3">At DigiHunk Technologies, we cultivate relationships with our clients by providing them with access to our knowledgeable staff, which is assembled for the client's convenience and is capable of solving all problems right away. </p><p>

              For all client inquiries, our team is always reachable via phone, WhatsApp, Online Chat or email. We can ensure that our team will complete the assignment in exactly one day and that it will be done in less than two hours without any errors.</p>
              <p>
                Our business employs a highly skilled team that is proactive in technical website maintenance for website users and observes the clients' websites to keep them up to date within the allotted time and we always have a reserve. </p><p>

                As a Delhi-based provider of website maintenance services, we have chosen from among all of our employees those who have the ability to interact effectively with clients and are experienced web developers. In addition, we establish a high standard for maintenance that is acceptable to the client.
              </p>
              <p>We at DigiHunk Technologies Website Maintenance Services understand the importance of holding clients accountable for their time and financial investments, thus we offer plans that address bankable hours, an online ticketing system, and a time monitoring system. We offer both new and dated websites that get more visitors and enable users to communicate with existing and potential customers. We create website maintenance plans that are marketed and maintained by a firm other than our own, which does not design the websites.</p>
              </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default WebsiteMaintenanceWrap;
