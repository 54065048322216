import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AboutWrap from "./PrivacyWrap";
import {Helmet} from "react-helmet";
function Privacy() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="description" content=""/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <Breadcrumb pageName="Privacy Policy" pageTitle="Privacy Policy" />
      <AboutWrap />
      <SubscribArea />
    </>
  );
}

export default Privacy;
