import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import AboutWrap from "./TermsWrap";
import {Helmet} from "react-helmet";
function Terms() {
  return (
    <>
    <Helmet>
        <title>Terms & Conditions</title>
        <meta name="description" content=""/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <Breadcrumb pageName="Terms & Conditions" pageTitle="Terms & Conditions" />
      <AboutWrap />
      <SubscribArea />
    </>
  );
}

export default Terms;
