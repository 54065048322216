import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";

function GraphicDesignWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots Of Creative</span>
              <h2>Graphic Design Services</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                  <p>Being a pioneer in graphic design, DigiHunk Technologies creates magnificent designs that transform the standard of your online business across various platforms. Our designers do smart and creative work to convey your business ideas to the audience through impressive graphic designs. Moreover, we work with graphic design tools including Adobe Creative Cloud, Corel Draw, Google Slides, Canva, and many more.</p>
                  <p>Our graphic designs can develop a great impression on your prospect’s mind. With a complete mixture of professionalism and stunning creativity skills, our expert designers can establish a bright profile for your business in the online world.</p>
                  <img src="img/graphic.svg" alt="best website maintenance service provider in india" height="400"/>
              </div>
                
                    <div className="row pt-5">
                      <h2>Our graphic design services and capabilities:</h2>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Logo Design" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="Social Media Design" details=""/></div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="Presentation Design" details=""/></div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="Brochure Design" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title="Banner, Posters & Signage" details=""/>
                    </div>
                    
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={6} image="/img/icons/service-icon-1.png" title="Marketing Materials" details=""/>
                    </div>
                    
                   
                    </div>
              <div className="row pt-5">
                      
                      <h3>Designing Tools We Use</h3>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools3.png" alt="Photoshop"/>
                        <h4>Photoshop</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools4.png" alt="Illustrator"/>
                        <h4>Illustrator</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools7.png" alt="Corel Draw"/>
                        <h4>Corel Draw</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools2.png" alt="Adobe XD"/>
                        <h4>Adobe XD</h4>
                      </div>
                      <div className="col-md-4 single-service text-center">
                        <img src="/img/ux-tools9.png" alt="Indesign"/>
                        <h4>Indesign</h4>
                      </div>
                      
                    </div>
            </div>
            <div className="col-lg-4">
               <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default GraphicDesignWrap;
