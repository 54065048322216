import React from 'react'
import { Link } from "react-router-dom";
function SubscribArea() {
   const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
   
     <section className="subscribe-newsletter sec-mar-top">
        <div className="container">
          <div className="news-letter-content">
            <div className="row align-items-center">
              <div className="col-lg-10 wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                <div className="subscribe-cnt">
                  <span>Get In Touch</span>
                  <h3>Lets build your project </h3>
                  <h4 className='text-white'>Our experts will contact you to consult you on your project.</h4>
                </div>
              </div>
              <div className="col-lg-2 wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms">
                 <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop} className="buttons-group" ><span>Contact Us</span></Link>
              </div>
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default SubscribArea