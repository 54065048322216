import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import Reviews from "../home/Reviews";
import DigitalMarketingWrap from "./DigitalMarketingWrap";
import {Helmet} from "react-helmet";
function DIgitalMarketing() {
  return (
    <>
    <Helmet>
        <title>Digital Marketing Services India | Digital Marketing Company India</title>
        <meta name="description" content="After the Design & Development process, main task to rank it on SERP, possible with Matebiz, Digital Marketing Company India known for Digital Marketing Services."/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <div className="creasoft-wrap">
        <Breadcrumb pageName="Digital Marketing" pageTitle="Digital Marketing" />
        <DigitalMarketingWrap />
        <Reviews/>
        <SubscribArea />
      </div>
    </>
  );
}

export default DIgitalMarketing;
