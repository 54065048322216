import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ContactWrapper from "./ContactWrapper";
import AnimateLine from "../../common/AnimateLine";
import Reviews from "../home/Reviews";
import {Helmet} from "react-helmet";
function Contact() {
  return (
    <>
    <Helmet>
        <title>Contact Us - DigiHunk Technologies</title>
        <meta name="description" content="Contact Us Mobile/WhatsApp: +91-99904 38600 Hours: 5 Days a week from 10:00 am to 6:00 pm E-mail: info@digihunk.com"/>
        <meta name="keywords" content=" "/>
      </Helmet>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb pageTitle="Contact us" pageName="Contact us" />
        <ContactWrapper />
        <Reviews/>
        <SubscribArea />
      </div>
    </>
  );
}

export default Contact;
