import React from "react";
import AnimateLine from "../../common/AnimateLine";
import Breadcrumb from "../../common/Breadcrumb";
import SubscribArea from "../../common/SubscribArea";
import ProjectWrap from "./ProjectWrap";
import {Helmet} from "react-helmet";
function Project() {
  
  return (
    <>
    <Helmet>
        <title>Website & Graphics Design Company Portfolio India</title>
        <meta name="description" content="Checkout our recent work portfolio for Website Design, WordPress Website, Logo Design, Mobile Application, Graphic Design and our all other creative works."/>
      </Helmet>
      <div className="creasoft-wrap">
        <AnimateLine />
        <Breadcrumb pageName="Portfolio" pageTitle="Portfolio" />
        <ProjectWrap />
        <SubscribArea />
      </div>
    </>
  );
}

export default Project;
