import React from "react";
import ContactForm from "./ContactForm";
import ServiceCard from "./ServiceCard";
function EcommerceWrap() {
  return (
    <>
        <div className="services-grid sec-mar">
        <div className="container">
          
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="title-wrap">
            <div className="sec-title text-l">
              <span>Passion! Dedication! Lots of Creative</span>
              <h2>E-Commerce Solutions</h2>
              
            </div>
          </div>
              <div className="service-details-content">
                <p>Whatever technology you chose to develop your ecommerce website, DigiHunk Technologies have dared to develop your website with our high quality Ecommerce developers within desired timeframe.</p>
                <p>As per latest web technology trends we do development with latest tools to make world class websites. To add up value for your business lets create new virtual view.</p>
                 <h2>Our e-commerce solutions and capabilities:</h2>
                  <div className="row">
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Shopify Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={2} image="/img/icons/service-icon-1.png" title="WooCommerce Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={3} image="/img/icons/service-icon-1.png" title="OpenCart Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={4} image="/img/icons/service-icon-1.png" title="Magento Development" details=""/>
                    </div>
                    <div class="col-md-6 col-lg-6 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                     <ServiceCard serviceNumber={5} image="/img/icons/service-icon-1.png" title="Custom E-Commerce Development" details=""/>
                    </div>
                    
                   
                    </div>
              </div>
            </div>
            <div className="col-lg-4">
              <ContactForm/>
            
              
            </div>
          
          </div>
        </div>
      </div>
    </>
  );
}

export default EcommerceWrap;
